const About = () => {
    return(
        <div className='page-section about'>
            <div className='text-block'>
                <h3>WHO WE ARE</h3>
                <p>Welcome to Rapid Roof Solutions, your trusted partner in high-quality roofing services. With a strong focus on commercial projects and a commitment to excellence, we bring expertise, efficiency, and reliability to every job we undertake. Our team of experienced professionals is dedicated to providing top-tier roofing solutions tailored to the unique needs of your business.</p>
            </div>
            <div className='text-block'>
                <h3>ROOFING SPECIALISTS</h3>
                <p>While our specialty lies in commercial roofing, we are equally skilled in handling residential projects. Whether it's a large-scale commercial installation or a small home repair, we approach each task with the same level of precision and care. At Rapid Roof Solutions, we understand the importance of a solid, durable roof, and we’re here to ensure that your property is protected.</p>
            </div>
            <div className='text-block'>
                <h3>HIGH STANDARDS</h3>
                <p>Our goal is simple: to deliver superior roofing solutions that stand the test of time. We take pride in our work and strive to exceed your expectations, providing you with peace of mind through quality craftsmanship and outstanding customer service. Trust Rapid Roof Solutions for all your roofing needs—where speed meets reliability, and your satisfaction is our top priority.</p>
            </div>
        </div>
    );
}

export default About;