import { useState, useEffect, useRef } from 'react';
import './App.css';
import About from './components/About';
import Contact from './components/Contact';
import Stripe from './components/Dynamic';
import Hero from './components/Hero';
import Nav from './components/Nav';
import Services from './components/Services';

function App() {
  const [scrollPosition, setScrollPosition] = useState(0);
  
  const sectionsRef = useRef([]);

  useEffect(() => {console.log(scrollPosition)}, [scrollPosition])

  useEffect(() => {
    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const index = sectionsRef.current.indexOf(entry.target);
          if (index !== -1) {
            setScrollPosition(index);
          }
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, {
      root: null,
      rootMargin: '0px',
      threshold: 0.5,
    });

    sectionsRef.current.forEach((section) => {
      if (section) {
        observer.observe(section);
      }
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  function scrollToElement(sectionName) {
    const sectionIndex = {
      Home: 0,
      About: 1,
      Services: 2,
      Contact: 3,
    }[sectionName];

    const section = sectionsRef.current[sectionIndex];
    if (section) {
      section.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  return (
    <div className="App">
      <Nav scrollToElement={scrollToElement} scrollFlag={scrollPosition}/>
      <Stripe scrollFlag={scrollPosition} />
      <div ref={(el) => (sectionsRef.current[0] = el)}>
        <Hero scrollToElement={scrollToElement}/>
      </div>
      <div ref={(el) => (sectionsRef.current[1] = el)}>
        <About />
      </div>
      <div ref={(el) => (sectionsRef.current[2] = el)}>
        <Services />
      </div>
      <div ref={(el) => (sectionsRef.current[3] = el)}>
        <Contact />
      </div>
    </div>
  );
}

export default App;
