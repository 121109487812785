const Hero = ({ scrollToElement }) => {
    return(
        <div className='page-section hero' style={{backgroundImage: `url(${process.env.PUBLIC_URL}/hero-bg.png)`}}>
            <img src={process.env.PUBLIC_URL+'/logo-flat.png'} alt='logo-flat'/>
            <ul>
                <li onClick={() => scrollToElement('About')}>About</li>
                <li onClick={() => scrollToElement('Services')}>Services</li>
                <li onClick={() => scrollToElement('Contact')}>Contact</li>
            </ul>
        </div>
    );
}

export default Hero;