const Nav = ({ scrollToElement, scrollFlag }) => {
    return(
        <div className={scrollFlag !== 0? 'nav' : 'nav hidden'}>
            <img src={process.env.PUBLIC_URL+'/logo.png'} alt='logo'/>
            <ul>
                <li onClick={() => scrollToElement('Home')}>Home</li>
                <li onClick={() => scrollToElement('About')}>About</li>
                <li onClick={() => scrollToElement('Services')}>Services</li>
                <li onClick={() => scrollToElement('Contact')}>Contact</li>
            </ul>
        </div>
    )
}

export default Nav;