import { useEffect, useState } from "react";

const Services = () => {
  const [services, setServices] = useState(true);

  useEffect(() => {
    console.log(services);
  }, [services]);

  return (
    <div className="page-section services">
      <div
        className="service-header"
        style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/service-header.png)` }}
      >
        <div className="service-header-overlay">
          <button className={services ? 'active' : ''} onClick={() => setServices(true)}>COMMERCIAL</button>
          <button className={services ? '' : 'active'} onClick={() => setServices(false)}>RESIDENTIAL</button>
        </div>
      </div>
      {services ? (
        <div className="service-content">
          <ul className="service-list">
            <li>Roof Installation</li>
            <li>Roof Replacement</li>
            <li>Roof Repair</li>
            <li>Roof Inspection</li>
            <li>Roof Maintenance</li>
            <li>Flat Roofing Systems</li>
            <li>Metal Roofing</li>
            <li>Green Roofing</li>
            <li>Roof Coating</li>
            <li>Emergency Roofing Services</li>
            <li>Waterproofing</li>
            <li>Energy-Efficient Roofing</li>
            <li>Cool Roofing Solutions</li>
            <li>Skylight Installation</li>
            <li>Roof Restoration</li>
          </ul>
        </div>
      ) : (
        <div className="service-content">
          <ul className="service-list">
            <li>Shingle Roof Installation</li>
            <li>Shingle Roof Repair</li>
            <li>Tile Roof Installation</li>
            <li>Tile Roof Repair</li>
            <li>Roof Inspection and Assessment</li>
            <li>Gutter Installation and Repair</li>
            <li>Attic Ventilation and Insulation</li>
            <li>Roof Leak Detection and Repair</li>
            <li>Skylight Repair and Replacement</li>
            <li>Emergency Roof Repair Services</li>
            <li>Storm Damage Roof Repair</li>
            <li>Roof Replacement</li>
            <li>Roof Maintenance Programs</li>
            <li>Asphalt Roofing</li>
            <li>Roof Cleaning and Debris Removal</li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default Services;
