const Stripe = ({ scrollFlag }) => {

    const dynamicText = () => {
        switch(scrollFlag){
            case 0:
                return(<p>LANDING</p>);
            case 1:
                return(<p>ABOUT</p>);
            case 2:
                return(<p>SERVICES</p>);
            case 3:
                return(<p>CONTACT</p>);
            default:
                return(<p>ERR</p>)
        }
    }

    return(
        <div className='dynamic-stripe'>
            {dynamicText()}
            <div/>
            <div/>
        </div>
    );
}

export default Stripe;