const Contact = () => {
    return(
        <div className='page-section contact'>
            <div className='contact-side'>
                <h3>GET IN TOUCH</h3>
                <p>r.roofsolutions@gmail.com</p>
                <p>(346) 652 2475</p>
            </div>
            <div className='contact-side'>
                <form method='POST' action='https://formsubmit.co/r.roofsolutions@gmail.com'>
                    <label>Your Name</label>
                    <input name='name' type='text'/>
                    <label>Phone Number</label>
                    <input name='number' type='text'/>
                    <label>Zip Code</label>
                    <input name='zipcode' type='text'/>
                    <label>How Can We Help?</label>
                    <textarea name='message'/>
                    <button type='submit'>SUBMIT</button>
                </form>
            </div>
        </div>
    );
}

export default Contact;